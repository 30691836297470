.f1-carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  opacity: 0.9;

  &--prev {
    left: 0;
  }

  &--next {
    right: 0;
  }
}

.f1-carousel-control-btn {
  box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.68);
}

.f1-carousel-indicators li {
  border-radius: 50%;
  width: $carousel-indicator-hit-area-height;
  height: $carousel-indicator-hit-area-height;

  &.active {
    background-color: $red !important;
  }
}
