.loading-backdrop {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-loader-lg {
    display: block;
}

.loading-loader-sm {
    display: none;
}

//mobile only
@media only screen and (max-width: 767px) {
    .loading-loader-lg {
        display: none;
    }

    .loading-loader-sm {
        display: block;
    }
}
