.post-container {
  &.edit-mode {
    position: static;
  }
}

.autoresize-textarea-container {
  padding: 12px 60px 12px 12px;
  &.edit-mode {
    padding: 12px;
  }
}
