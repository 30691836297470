@import "../../assets/scss/style";

.userSelect {
  width: "100%";
  resize: "none";
  border-color: "#ccd3d8";
  border-radius: "1px";
  line-height: "1.3rem";
  padding: "10px 15px 10px 15px";

  .option {
    cursor: pointer;
    &:hover,
    &.selected {
      background: theme-color("lighter-gray");
    }
  }
}
