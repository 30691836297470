@import "../../../assets/scss/style";

.editor-container {
  position: relative;
  text-align: left;
}

.editor-inner {
  background: #fff;
  position: relative;
}

.editor-input {
  min-height: 175px;
  max-height: 250px;
  overflow: auto;
  resize: none;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 10px 15px;
  caret-color: #444;
  border: 1px solid $border-secondary;
  font-size: 14px;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 10px;
  left: 15px;
  font-size: 14px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
  line-height: 1.6rem;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
  line-height: 1.6rem;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}
