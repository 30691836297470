// Scss functions
//
// Utility functions for evaluating source code across our variables, maps, and mixins.

// Retrieve a text size from the $text-sizes map.
@function text-size($text-size) {
  @return map-get($map: $text-sizes, $key: $text-size);
}

// Retrieve a value from bootstraps $spacers map.
@function spacer($key) {
  @return map-get($spacers, $key);
}

// Convert the given px value to rem.
// Ex:
//   .custom-css { width: px-to-rem(24px); }
// Outputs:
//   .custom-css { width: 1.5rem; }
@function px-to-rem($size-in-px, $base-font-size: 16px) {
  @return $size-in-px / $base-font-size * 1rem;
}

// Calculates the midpoint between two values
// Ex:
//  .custom-css { width: midpoint(1rem, 1.5rem); }
// Outputs:
//   .custom-css { width: 1.25rem; }
@function midpoint($left, $right) {
  @return ($right + $left) / 2;
}
