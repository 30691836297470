@import "../../assets/scss/all/bootstrap/functions";
@import "../../assets/scss/all/bootstrap/variables";
@import "../../assets/scss/all/bootstrap/mixins";

.gated-banner {
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  position: sticky;
  bottom: 0;
  color: white;
  z-index: 1020;
  box-shadow: 0 -10px 15px 0 rgba(0, 0, 0, 0.2);
  padding: map-get($map: $spacers, $key: 3);

  h2 {
    line-height: 1.4;
  }

  &.collapsed {
    flex-direction: row;
    height: 90px;

    h2 {
      margin-bottom: 0px;
    }


    @include media-breakpoint-down(sm) {
      .text-20 {
        font-size: 14px !important;
      }

      .cta {
        width: 120px;
      }
    }
  }

  .contents {
    max-width: 1024px;
    margin: 0 auto;
  }

  .separator {
    max-width: 400px;
  }

  .toggle-button {
    color: white;
  }
}
