.legacy-transition-modal {
  h2 {
    font-size: 1.25rem;
  }

  p {
    font-size: 1rem;
    text-align: center;
  }
}
