@import "../../assets/scss/style";

.filePost-v2-style {
  .filepond--image-preview-overlay,
  .filepond--file-info {
    display: none;
  }

  .filepond--wrapper {
    min-height: 120px;
  }
  .filepond--drop-label {
    height: 120px;
  }

  .filepond--item {
    width: 104px;
  }

  .filepond--panel-root {
    background-color: transparent;
    border: none;
  }

  .filepond--panel.filepond--panel-root {
    border-radius: 0.4rem;
    border-style: dashed !important;
    border-color: #ccd2d8 !important;
    border-width: 2px !important;
  }

  .filepond--label-action {
    color: theme-color("cool-blue");
    text-decoration: none;
  }

  .filepond--action-edit-item {
    left: inherit !important;
    right: 3px;
    bottom: 6px !important;
    width: 26px !important;
    height: 26px !important;
    padding: 0px !important;
  }

  .filepond--action-remove-item {
    left: 3px !important;
    top: 3px !important;
  }
}
