@each $color, $value in $theme-colors {
  .popover-#{$color} {
    .popover-inner {
      background-color: $value;
    }

    &.bs-popover-top .arrow::after {
      border-top-color: $value;
    }

    &.bs-popover-right .arrow::after {
      border-right-color: $value;
    }

    &.bs-popover-bottom .arrow::after {
      border-bottom-color: $value;
    }

    &.bs-popover-left .arrow::after {
      border-left-color: $value;
    }
  }
}
