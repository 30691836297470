$p-info-card-header-bg: theme-color("lighter-gray");
$p-info-card-header-color: theme-color("denim-blue");

$p-info-card-border-width: px-to-rem(2px);
$p-info-card-border-color: $p-info-card-header-bg;

$p-info-card-header-padding-x: spacer(1);
$p-info-card-header-padding-y: spacer(2);

$p-info-card-body-padding-x: spacer(2);
$p-info-card-body-padding-y: spacer(3);
$p-info-card-body-middle-padding-y: midpoint(
  $p-info-card-body-padding-x,
  $p-info-card-body-padding-y
);

.profile-info-card {
  margin-bottom: $profile-margin-y;
  border: $p-info-card-border-width solid $p-info-card-border-color;

  &:last-child {
    margin-bottom: 0;
  }

  .card-header {
    color: $p-info-card-header-color;
    background-color: $p-info-card-header-bg;
    border-bottom: 0;
    font-size: text-size(13);
    padding: $p-info-card-header-padding-x $p-info-card-header-padding-y;
    font-weight: bold;
    letter-spacing: 1.08px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    .btn-add {
      position: relative;
      right: $p-info-card-header-padding-x * -1;
    }
  }

  .card-body {
    padding: $p-info-card-body-padding-y $p-info-card-body-padding-x;
  }

  .profile-info-card-row {
    align-items: flex-end;
    padding-top: $p-info-card-body-middle-padding-y;
    padding-bottom: $p-info-card-body-middle-padding-y;
    border-bottom: $p-info-card-border-width solid $p-info-card-border-color;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border: 0;
      padding-bottom: 0;
    }

    .btn-delete {
      position: relative;
      right: $p-info-card-body-padding-x * -1;
      width: 2.25rem;
      height: 1.75rem;
    }
  }
}
