@import "../custom/colors";

.bordered-nav {
  background: white;

  .nav-link {
    text-align: center;
    border: none;
    border-radius: 0;
    border-bottom: 3px solid $light-gray;
    color: $textSecondary;
    background: transparent !important;

    &.active {
      border-color: $denim-blue;
      color: $denim-blue;
    }
  }
}
