@import "../../../assets/scss/style.scss";

.prominent-header {
  max-width: 577px;
  height: 315px;

  .text-block {
    justify-content: center;
    margin-left: 34px;
    margin-right: 34px;
    color: white;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .title {
    font-size: 22px;
    line-height: 28px;

    @include media-breakpoint-up(sm) {
      font-size: 25px;
      line-height: 31px;
    }
  }

  .subtitle {
    font-size: 18px;
    line-height: 24px;
  }
}
