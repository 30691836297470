@import "colors";

@each $key, $value in $theme-colors {
  .panel-#{$key} {
    background-color: lighten($value, 10%);
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 1.5rem;
    color: color-yiq($value);
  }
}
