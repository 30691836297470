.box {
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8);
  position: relative;
  text-align: left;
  font-size: 20px;
  color: white;
  transition: all 0.3s ease-in;
}

.arrow--right {
  position: absolute;
  transition: all 0.3s ease-in;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  border-left: 10px solid;
  right: -20px;
  top: calc(50% - 10px);
}

.arrow--left {
  position: absolute;
  transition: all 0.3s ease-in;
  left: -20px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid;
  top: calc(50% - 10px);
}

.arrow--top {
  position: absolute;
  transition: all 0.3s ease-in;
  top: -20px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.arrow--bottom {
  position: absolute;
  transition: all 0.3s ease-in;
  bottom: -20px;
  border-top: 10px solid;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

// add other position classes when needed

.arrow-x-middle {
  left: calc(50% - 10px);
}

.arrow-x-left {
  left: calc(10% - 10px);
}

.arrow-x-right {
  left: calc(90% - 10px);
}
