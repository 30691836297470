/*******************
 Page Content Area
*******************/

html,
body,
#root,
.page-wrapper {
  height: 100%;
}

body {
  overflow: hidden;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-header {
  flex: 0 0 auto;

  // Only show shadow for desktop view
  @include media-breakpoint-up(lg) {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }

  .registration-header {
    padding: 10px 0;
  }

  .primary-nav {
    padding: 17px 17px 0;

    @include media-breakpoint-down(sm) {
      padding: 12px;
    }
  }

  // had to do this instead of the breakpoint
  // coz if I change anything in styles above
  // it is going to affect old component
  .primary-navbar {
    padding: 17px 17px 0;

    @include media-breakpoint-down(sm) {
      padding: 8px 8px 0;
    }
  }
}

.page-content {
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
}

.center-only-container {
  max-width: 768px !important;
}
