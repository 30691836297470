@font-face {
  font-family: "icomoon";
  src: url("../../../fonts/icomoon.eot?6wb22y");
  src: url("../../../fonts/icomoon.eot?6wb22y#iefix")
      format("embedded-opentype"),
    url("../../../fonts/icomoon.ttf?6wb22y") format("truetype"),
    url("../../../fonts/icomoon.woff?6wb22y") format("woff"),
    url("../../../fonts/icomoon.svg?6wb22y#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/************** FONT TEXT OVERRIDES ******************/
.font-weight-500 {
  font-weight: 500;
}

/************** FONT ICON OVERRIDES ******************/

.icon-arrow-right:before {
  content: "\e900";
}
.icon-badge-circle:before {
  content: "\e901";
}
.icon-beat-circle:before {
  content: "\e902";
}
.icon-bell:before {
  content: "\e903";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-camera-circle:before {
  content: "\e905";
}
.icon-camera:before {
  content: "\e906";
  color: #3f6296;
}
.icon-chat:before {
  content: "\e907";
}
.icon-check-circle:before {
  content: "\e908";
}
.icon-check:before {
  content: "\e909";
}
.icon-checkmark-circle-outline:before {
  content: "\e90a";
}
.icon-close-circle:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e90c";
}
.icon-computer-circle:before {
  content: "\e90d";
}
.icon-copy:before {
  content: "\e90e";
}
.icon-disable:before {
  content: "\e90f";
}
.icon-down-arrow-circle .path1:before {
  content: "\e910";
  color: rgb(63, 98, 150);
  opacity: 0.557;
}
.icon-down-arrow-circle .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-down-arrow-large:before {
  content: "\e912";
  color: #3f6296;
}
.icon-download:before {
  content: "\e913";
  color: #3f6296;
}
.icon-edit-large:before {
  content: "\e914";
}
.icon-edit-small:before {
  content: "\e915";
}
.icon-ellipise-horizontal:before {
  content: "\e916";
  color: #6b778b;
}
.icon-ellipise-vertical:before {
  content: "\e917";
  color: #6b778b;
}
.icon-eye-disable:before {
  content: "\e918";
}
.icon-eye:before {
  content: "\e919";
}
.icon-forward:before {
  content: "\e91a";
}
.icon-gradute:before {
  content: "\e91b";
  color: #3f6296;
}
.icon-hamburger:before {
  content: "\e91c";
}
.icon-health-circle-outline:before {
  content: "\e91d";
}
.icon-health-circle:before {
  content: "\e91e";
  color: #3f6296;
}
.icon-info-alert-outline:before {
  content: "\e91f";
  color: #ed1c30;
}
.icon-info-alert .path1:before {
  content: "\e920";
  color: rgb(237, 28, 48);
}
.icon-info-alert .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-info .path1:before {
  content: "\e922";
  color: rgb(61, 118, 200);
}
.icon-info .path2:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(55, 111, 182);
}
.icon-left-arrow:before {
  content: "\e924";
}
.icon-light-circle-outline:before {
  content: "\e925";
}
.icon-link:before {
  content: "\e926";
}
.icon-location-circle:before {
  content: "\e927";
  color: #3f6296;
}
.icon-mail:before {
  content: "\e928";
}
.icon-number-circle:before {
  content: "\e929";
}
.icon-plus:before {
  content: "\e92a";
}
.icon-profile:before {
  content: "\e92b";
}
.icon-question:before {
  content: "\e92c";
}
.icon-ribbon:before {
  content: "\e92d";
}
.icon-right-arrow-circle:before {
  content: "\e92e";
  color: #3f6296;
}
.icon-right-arrow-large:before {
  content: "\e92f";
  color: #3f6296;
}
.icon-search-circle .path1:before {
  content: "\e930";
  color: rgb(26, 37, 43);
}
.icon-search-circle .path2:before {
  content: "\e931";
  margin-left: -1.1865234375em;
  color: rgb(26, 37, 43);
}
.icon-search-circle .path3:before {
  content: "\e932";
  margin-left: -1.1865234375em;
  color: rgb(26, 37, 43);
}
.icon-search-circle .path4:before {
  content: "\e933";
  margin-left: -1.1865234375em;
  color: rgb(255, 255, 255);
  opacity: 0.767;
}
.icon-search-circle .path5:before {
  content: "\e934";
  margin-left: -1.1865234375em;
  color: rgb(26, 37, 43);
}
.icon-search-circle .path6:before {
  content: "\e935";
  margin-left: -1.1865234375em;
  color: rgb(26, 37, 43);
}
.icon-search-circle .path7:before {
  content: "\e936";
  margin-left: -1.1865234375em;
  color: rgb(26, 37, 43);
}
.icon-search-circle .path8:before {
  content: "\e937";
  margin-left: -1.1865234375em;
  color: rgb(26, 37, 43);
}
.icon-search-doc-circle:before {
  content: "\e938";
}
.icon-search:before {
  content: "\e939";
}
.icon-settings:before {
  content: "\e93a";
}
.icon-signal .path1:before {
  content: "\e93b";
  color: rgb(237, 28, 48);
  opacity: 0.15;
}
.icon-signal .path2:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(237, 28, 48);
}
.icon-signal .path3:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(237, 28, 48);
}
.icon-time-circle:before {
  content: "\e93e";
}
.icon-time:before {
  content: "\e93f";
  color: #6b778b;
}
.icon-trash-large:before {
  content: "\e940";
}
.icon-trash:before {
  content: "\e941";
}
.icon-wifi-circle:before {
  content: "\e942";
}
.icon-winner-ribbon-circle:before {
  content: "\e943";
}
.icon-winner-ribbon:before {
  content: "\e944";
}

//Fonts
@font-face {
  font-family: "secondaryFont";
  src: local("secondaryFont"),
    url(../../../fonts/icomoon.ttf) format("truetype");
}

// Based on HelveticaNeueLTW05-45Light
@font-face {
  font-family: "HelveticaNeue-Light";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-45Light.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-45Light.woff")
      format("woff");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Light";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-45Light.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-45Light.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Light";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-45Light.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-45Light.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Light";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-45Light.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-45Light.woff")
      format("woff");
  font-weight: bolder;
  font-style: normal;
}

// Based on HelveticaNeueLTW05-46LtIt
@font-face {
  font-family: "HelveticaNeue-Light-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-46LtIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-46LtIt.woff")
      format("woff");
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Light-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-46LtIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-46LtIt.woff")
      format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Light-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-46LtIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-46LtIt.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Light-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-46LtIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-46LtIt.woff")
      format("woff");
  font-weight: bolder;
  font-style: italic;
}

// Based on HelveticaNeueLTW05-55Roman
@font-face {
  font-family: "HelveticaNeue-Roman";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-55Roman.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-55Roman.woff")
      format("woff");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Roman";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-55Roman.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-55Roman.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Roman-Bold";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-55Roman.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-55Roman.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Roman-Bolder";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-55Roman.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-55Roman.woff")
      format("woff");
  font-weight: bolder;
  font-style: normal;
}

// Based on HelveticaNeueLTW05-56Italic
@font-face {
  font-family: "HelveticaNeue-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-56Italic.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-56Italic.woff")
      format("woff");
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-56Italic.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-56Italic.woff")
      format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-56Italic.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-56Italic.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-56Italic.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-56Italic.woff")
      format("woff");
  font-weight: bolder;
  font-style: italic;
}

// Based on HelveticaNeueLTW05-65Medium
@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-65Medium.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-65Medium.woff")
      format("woff");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-65Medium.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-65Medium.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-65Medium.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-65Medium.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-65Medium.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-65Medium.woff")
      format("woff");
  font-weight: bolder;
  font-style: normal;
}

// Based on HelveticaNeueLTW05-66MdIt
@font-face {
  font-family: "HelveticaNeue-Medium-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-66MdIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-66MdIt.woff")
      format("woff");
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Medium-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-66MdIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-66MdIt.woff")
      format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Medium-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-66MdIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-66MdIt.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Medium-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-66MdIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-66MdIt.woff")
      format("woff");
  font-weight: bolder;
  font-style: italic;
}

// Based on HelveticaNeueLTW05-75Bold
@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-75Bold.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-75Bold.woff")
      format("woff");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-75Bold.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-75Bold.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-75Bold.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-75Bold.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-75Bold.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-75Bold.woff")
      format("woff");
  font-weight: bolder;
  font-style: normal;
}

// Based on HelveticaNeueLTW05-76BoldIt
@font-face {
  font-family: "HelveticaNeue-Bold-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-76BoldIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-76BoldIt.woff")
      format("woff");
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Bold-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-76BoldIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-76BoldIt.woff")
      format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Bold-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-76BoldIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-76BoldIt.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeue-Bold-Italic";
  src: url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-76BoldIt.woff2")
      format("woff2"),
    url("../../../fonts/Helvetica-Licensed/HelveticaNeueLTW05-76BoldIt.woff")
      format("woff");
  font-weight: bolder;
  font-style: italic;
}
