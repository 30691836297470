.case-detail-comments-tab-group {
  display: flex;
}

.case-detail-comments-tab {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-bottom: 3px solid #eef1f7;
  cursor: pointer;
  color: #1a252b;
  font-size: 15px;
}

.case-detail-comments-tab-active {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-bottom: 3px solid #3f6296;
  color: #3f6296;
  font-size: 15px;
  font-weight: 500;
}

/************** OLD FEED CARD STUFF ****************/

.comment-summary-container {
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .feed-card-comment {
    display: flex;
    align-items: center;

    span {
      color: $color-text-1;
      font-size: 13px;
      margin-left: 6px;
    }
  }

  .feed-card-actions {
    display: flex;
    align-items: center;

    div {
      border: none;
      color: $color-text-4;
      font-size: 13px;
      margin: 0px 2px;
    }

    span {
      width: 24px;
      font-size: 13px;
      border: 1px solid $color-text-4;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 2px;
      color: $color-text-4;
    }
  }
}

/************** COMMENT INPUT ****************/
textarea:focus {
  outline: none;
}

/************** COMMENT  LIST ****************/
.comment-list {
  height: 400px;
}

.comment-header-separator {
  border-right: 2px solid theme-color("aqua");
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 0.8rem;
  margin-bottom: 0.1rem;
}

/************** REPLY THREAD ****************/

.reply-padding {
  padding-left: 36px;
}
