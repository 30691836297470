@import "../../assets/scss/style";

.groups-navigation {
  .nav-item {
    color: theme-color("battleship-gray");
    &.active {
      // color not used elsewhere atm, so it can live as is for now -- Corey
      background-color: #e0e5ed;
      color: theme-color("default-black");
    }
  }
  &.mobile {
    margin-top: 3px;

    .nav-item {
      border-radius: 20px;
      flex-grow: 1;
      justify-content: center;

      img {
        width: 24px;
        height: 24px;
        min-width: 24px;
      }
    }
  }
  .group-icon {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}
