@import "../../../assets/scss/all/custom/variables";
// @import "../../../assets/scss/all/bootstrap/variables";

@import "../../../assets/scss/all/bootstrap/mixins";

$enable-gradients: false;

.form-global-error {
  &.alert {
    position: relative;
    padding: 7px;
    border: none;
  }

  background: rgba(243, 88, 88, 0.12);
  color: #f35858;
}
