.react-multiple-carousel__arrow,
.react-multiple-carousel__arrow:hover {
  background: white;
}

.react-multiple-carousel__arrow::before {
  color: #ccd3d8;
  font-size: 16px;
}

.react-multiple-carousel__arrow {
  min-height: 26px; // 43
  min-width: 26px;
  border-radius: 21px; // 35
}

.carousel-button-text {
  width: 115px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
}

.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
}
