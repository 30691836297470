.browser-block {
  .mainContainer {
    min-height: 200px;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .browser-message {
    background: white;
    width: 500px;
    flex-direction: column;
    position: absolute;
    top: 50%;
    min-height: 290px;
    padding: 46px 65px;
    box-shadow: 0px 2px 12px rgba(203, 203, 203, 0.5);
  }

  .heading {
    font-size: 18px;
    line-height: 20px;
    font-family: "HelveticaNeue-Medium";
  }

  .browser-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 300px;
    margin-top: 20px;

    img {
      width: 51px;
      height: 51px;
    }
  }
}
