.check-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    padding: 0 0 0 26px;

    background: url("../../assets/images/verification_check_web.svg") no-repeat;
    background-position: left center;
  }
}
