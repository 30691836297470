// ======================================================
// Bootstrap override colors
// ======================================================

$white: #fff;
$blue: #3f6296;
$red: #ed1c30;
$secondary: #eef1f7;

$theme-colors: (
  // Custom Colors
  "aqua": #3bafa8,
  "aqua-opaque": rgba(#3bafa8, 0.19),
  "denim-blue": #3f6296,
  "denim-blue-opaque": rgba(#3f6296, 0.49),
  "denim-blue-light": #cad3e3,
  "cool-blue": #3d76c8,
  "default-black": #1a252b,
  "default-black-opaque": rgba(26, 37, 43, 0.49),
  "battleship-gray": #6b778b,
  "light-gray": #ccd3d8,
  "lighter-gray": #eef1f7,
  "lighter-gray-2": #f1f0ef,
  "lighter-gray-3": #e4e3e2,
  "lighter-gray-alt": #ebecef,
  "light-gray-blue": #9ca8bd,
  "charcoal-gray": #353a43,
  "dark-gray": #242526,
  "darker": #1d2931,
  "darkest": #191818,
  "secondary": $secondary,
  "white-opaque": rgba(255, 255, 255, 0.75),
  "white": #fff,
  "red": $red,
  "red-opaque": rgba($red, 0.13),
  "navy-blue": #15223b,
  "blue-gray": #4b5974,
  "dark-blue": #223354,
  "darker-blue": #111728,
  "coral": #f35858,
  "alert-yellow": #fef3e1,
  "alert-red": #feebeb,
  "pale-red": #dc6f82,
  "pale-purple": #aca4ca,
  "pale-orange": #f2aa62,
  "outline-gray": #dfdfdf
);
$body-bg: $secondary;

// ======================================================
// Bootstrap override grid breaks
// ======================================================

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  lg: 768px
) !default;

$container-max-widths: (
  lg: 1024px
) !default;

$grid-gutter-width: 20px;

// ======================================================
// Bootstrap override spacing
// ======================================================

$spacer: 1rem;

// ======================================================
// Bootstrap Component override setting
// ======================================================

// Components

$component-active-color: #1c2931;
$component-active-bg: map-get(
  $map: $theme-colors,
  $key: "secondary"
);

// Typography

$font-size-base: 1rem * 0.875;

$text-muted: map-get(
  $map: $theme-colors,
  $key: "battleship-gray"
);

// Buttons + Forms

$input-btn-focus-box-shadow: none;

// Forms

$input-border-color: #ced4da;

$input-box-shadow: none;

$input-focus-border-color: $input-border-color;

$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-bg: map-get($theme-colors, "denim-blue");
$custom-control-indicator-active-bg: lighten(
  $custom-control-indicator-checked-bg,
  35%
) !default;

$custom-select-bg-size: 0.75rem;
$custom-select-indicator: url('data:image/svg+xml;utf-8,<svg class="custom-icon" width="12" height="12" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" style="display: inline-block; vertical-align: middle;"><path d="M827.186 315.186c14.441-14.441 37.854-14.441 52.294 0 12.997 12.997 14.296 33.261 3.899 47.711l-3.899 4.583-341.333 341.333c-12.997 12.997-33.261 14.296-47.711 3.899l-4.583-3.899-341.333-341.333c-14.441-14.441-14.441-37.854 0-52.294 12.997-12.997 33.261-14.296 47.711-3.899l4.583 3.899 315.186 315.2 315.186-315.2z" style="fill: rgb(107, 119, 139);"></path></svg>');

// Navbar

$navbar-padding-y: ($spacer * 0.25) / 2;
$navbar-padding-x: ($spacer * 0.25);

$navbar-nav-link-padding-x: $spacer;

$navbar-brand-padding-y: 0;

$navbar-light-color: map-get(
  $map: $theme-colors,
  $key: "default-black"
);
$navbar-light-hover-color: darken($navbar-light-color, 10%);
$navbar-light-active-color: darken($navbar-light-color, 80%);
$navbar-light-disabled-color: rgba($navbar-light-color, 0.3);

// List Group

$list-group-border-color: map-get(
  $map: $theme-colors,
  $key: "secondary"
);
$list-group-border-width: 3px;

// Modals

$modal-backdrop-bg: map-get(
  $map: $theme-colors,
  $key: "default-black"
);
$modal-backdrop-opacity: 0.8;

// Toasts

$toast-background-color: $white;

// ======================================================
// Bootstrap Enable optional
// ======================================================

$link-hover-decoration: none;

$enable-important-utilities: true; // Set to true since bootstrap 4 utilities are important. Ideally this would be false

// ======================================================
// Custom colors
// ======================================================

// TODO: Should we define these in the theme color map?
$color-white: #fff;
$color-primary: #3f6296;
$color-secondary: #3bafa8;

$color-text-1: #6b778b;
$color-text-2: black;
$color-text-3: #fff;
$color-text-4: #1a252b;
$color-text-5: #1d2931;

$color-body: #eef1f7;

$color-divider: #d6d8de;

$color-default-gradient: linear-gradient(99deg, #3f6296, #77c2c1 93%);

// Text Sizes
@import "functions";
$text-sizes: (
  10: 0.65625rem,
  11: 0.69rem,
  12: 0.75rem,
  13: 0.813rem,
  14: 0.875rem,
  15: 0.938rem,
  16: px-to-rem(16px),
  18: 1.125rem,
  20: 1.25rem,
  21: 1.32rem,
  22: 1.4rem,
  25: px-to-rem(25px),
  26: px-to-rem(26px),
  28: px-to-rem(28px),
  40: px-to-rem(40px),
  48: px-to-rem(48px)
);

$line-heights: (
  16: px-to-rem(16px),
  17: px-to-rem(17px),
  18: px-to-rem(18px),
  19: px-to-rem(19px),
  20: px-to-rem(20px),
  21: px-to-rem(21px),
  22: px-to-rem(22px),
  25: px-to-rem(25px),
  26: px-to-rem(26px),
  28: px-to-rem(28px),
  30: px-to-rem(30px),
  tight: 1.25em,
  snug: 1.4em
);

$text-clamp-max-lines: (1, 2, 3, 5);

// Buttons

$btn-primary-bg-color: $color-primary;
$btn-primary-color: $color-white;
$btn-primary-border-radius: 4px;

$btn-secondary-color: $color-secondary;
$btn-secondary-border-radius: 4px;
$btn-secondary-border: solid 1px $color-secondary;
