$avatar-size-base: 2.5rem;
$avatar-font-size-base: 1.5rem;

$avatar-sizes: (
  "xs": (
    1.5rem,
    1.2rem,
    1.5rem
  ),
  "sm": (
    2.25rem,
    1.25rem,
    2.25rem
  ),
  "md": (
    4.7rem,
    (4.7rem / $avatar-font-size-base) * 1rem,
    4.7rem
  ),
  "xl": (
    10rem,
    (10rem / $avatar-font-size-base) * 1rem,
    10rem
  )
);

.avatar,
.avatar-placeholder {
  width: $avatar-size-base;
  height: $avatar-size-base;
}

.avatar-img {
  display: block;
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  border-radius: 0.3125rem;
}

.avatar-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $avatar-font-size-base;
  background-image: linear-gradient(133deg, #374685, #77c2c1 97%);
  color: $white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;

  &.deleted {
    background-image: none;
    background-color: $lighter-gray;
    color: $medium-blue;
  }
}

@each $key, $sizes in $avatar-sizes {
  .avatar-#{$key} {
    width: nth($sizes, 1);
    height: nth($sizes, 1);
  }
  .avatar-placeholder-#{$key} {
    width: nth($sizes, 3);
    height: nth($sizes, 1);
  }
  .avatar-placeholder-#{$key} {
    font-size: nth($sizes, 2);
  }
}
