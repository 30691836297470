.completedActivityOuterDiv {
  max-width: 378px;
  min-height: 132px;
  width: 100%;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: #ccd3d8;
  align-self: center;
}

.completedActivityImage {
  width: 103px;
  height: 132px;
  background-size: cover;
  border-bottom-left-radius: 4px;
  border-top-left-radius: "4px";
}

.flex1 {
  flex: 1;
}

.cell-header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ccd3d8;
}
