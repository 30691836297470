@import "../../assets/scss/all/custom/colors";

.emptyActivities {
  background-color: white;
  border-radius: 4px;
  color: $textSecondary;
  padding: 20px;
  margin-top: 10px;
  text-align: center;
}
