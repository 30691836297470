.outer-style {
  width: 100%;
  background-color: white;
  margin-top: 10px;
  border-radius: 4px;
  position: relative;
}

.lower-pane {
  height: 115px;
  margin-top: 15px;
  margin-left: 15px;
  padding-bottom: 25px;
}

.title-style {
  font-size: 16px;
  line-height: 22px;
  padding-right: 10px;
}

.credits-style {
  font-size: 15px;
  line-height: 13.5px;
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 1000;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  padding: 4px 10px 3px 4px;

  * {
    margin: 0;
  }
}

.progress-style {
  font-size: 14px;
  width: 93px;
  height: 25px;
  border-radius: 4px;
}
