.flashing-div {
  background-color: transparent;
  animation: flashDiv 1s linear 2;
}

@keyframes flashDiv {
  0% {
    background-color: rgba($color: #3d76c8, $alpha: 0);
  }
  25% {
    background-color: rgba($color: #3d76c8, $alpha: 0.12);
  }
  50% {
    background-color: rgba($color: #3d76c8, $alpha: 0.25);
  }
  75% {
    background-color: rgba($color: #3d76c8, $alpha: 0.12);
  }
  100% {
    background-color: rgba($color: #3d76c8, $alpha: 0);
  }
}
