@import "../../assets/scss/style";

.explore-category-cta {
  h3 {
    border-bottom: 3px solid $lighter-gray;
  }

  .preview-images {
    list-style-type: none;
    margin: 0;
    padding: 0;

    flex-basis: 70px;
    flex-shrink: 0;
    flex-grow: 0;

    li {
      margin: 0;
      padding: 0;
      margin-right: 1px;
      margin-bottom: 1px;
    }
  }
}
