.draft {
  position: relative;
  display: inline-block;
}
.draft .button_badge {
  position: absolute;
  font-size: 11px;
  top: -7px;
  right: -14px;
  vertical-align: middle;
  line-height: normal;
  border: solid 1px #fff;
}
