.registration-header-text {
  padding: map-get($map: $spacers, $key: 4);
}

// Note, this is referencing custom's "lg" variable (768px)...
@include media-breakpoint-up(sm) {
  .registration-header-text {
    padding: 0 25%;
  }
}
