// Common styles across the app

html,
body {
  font-family: $font-family-base, sans-serif;
}

b,
strong {
  font-family: $font-family-sans-serif-bold, sans-serif !important;
}

$divider-margin-x: 0.365rem;

.divider {
  display: inline-block;
  position: relative;
  width: 2px;
  margin-left: $divider-margin-x;
  margin-right: $divider-margin-x;
}

@each $color, $value in $theme-colors {
  .divider-#{$color} {
    background: $value;
  }

  .color-#{$color} {
    color: $value;
  }
}

.divider-14 {
  height: px-to-rem(14px);
  top: 0.15rem;
}

.card-shadow {
  box-shadow: $box-shadow-sm !important;

  &:hover {
    box-shadow: $box-shadow !important;
  }
}

.popover {
  max-width: 450px;
}

.image-drop-area {
  width: 100%;
  height: 481px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23999' stroke-width='2' stroke-dasharray='10%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.image-drop-card {
  min-height: 300px;
}

.filepond--credits {
  display: none;
}

.filepond--root {
  margin-bottom: 0;
}

.no-decoration-button {
  background: transparent;
  border: none;

  &:hover {
    background: transparent;
  }

  &:focus {
    box-shadow: none !important;
    background: transparent !important;
    opacity: 0.4 !important;
  }
}

.no-focus-button:focus {
  box-shadow: none !important;
}

.react-transform-component {
  height: 492px !important;
  flex-grow: 1;
}

.cursor-ew-resize:hover {
  cursor: ew-resize;
}

.cursor-ns-resize:hover {
  cursor: ns-resize;
}

#background-media {
  z-index: -1;
  position: fixed; /* optional depending on what you want to do in your app */
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  > video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.verify-image-container {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23ccc' stroke-width='3' stroke-dasharray='10%2c 15' stroke-dashoffset='22' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 5px;
  padding: 15px;
}

.bg-coral-alt {
  $coral-color: map-get($theme-colors, "coral");
  background-color: rgba($color: $coral-color, $alpha: 0.16);
}
