.orDivider {
  display: flex;
  align-items: center;
  text-align: center;
  min-width: 200px;
  width: 50%;
}

.orDivider::before,
.orDivider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccd3d8;
}

.orDivider:not(:empty)::before {
  margin-right: 0.75em;
  margin-top: 0.2rem;
}

.orDivider:not(:empty)::after {
  margin-left: 0.75em;
  margin-top: 0.2rem;
}
