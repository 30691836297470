/*****************************************************************************************************/
/*********************** DO NOT USE THIS FILE, IT'S MARKED FOR TERMINATION !! ************************/
/*****************************************************************************************************/

.feed-card {
  margin-bottom: 10px;
}

.feed-card-info {
  color: $color-text-4;
  opacity: 0.9;

  & p {
    font-size: 15px;
    line-height: 21px;
  }
}

.feed-card-info-markdown {
  :last-child {
    margin-bottom: 0;
  }
}

.feed-card-detail p {
  line-height: 22px;
}

.feed-card-button {
  padding: 2px 7px;
  margin-right: 8px;
}

.feed-sidebar-r-button {
  padding: 21px 52px;
  width: 100%;
  margin-bottom: 13px;
}

.feed-sm-card {
  .feed-sm-card-title {
    color: $color-text-4;
    opacity: 0.9;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    line-height: 1em;
    max-height: 2em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .divider::before {
    bottom: -6px;
    background-color: $color-body;
  }

  .divider {
    margin-bottom: 20px;
  }

  .feed-sm-card-content {
    margin-bottom: 15px;
    color: $color-text-4;
    opacity: 0.9;
    font-size: 14px;
    line-height: 20px;
  }

  .feed-sm-card-button {
    padding: 11px 16px;
  }
}
