@import "../../assets/scss/style.scss";

.claimGroupPage {
  h1 {
    max-width: none;
    font-size: text-size(28);
    margin: 10vh 10vw 2vh;
  }
  h2 {
    margin-bottom: 5vh;
  }

  @include media-breakpoint-up(lg) {
    h1 {
      max-width: 50vw;
      font-size: text-size(40);
      margin-top: 8vh;
    }
  }
}
