@import "../../assets/scss/all/custom/variables";

.horizontal-divider {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center; /* making vertical centering of all children */

  &::before,
  &::after {
    content: "";
    flex: 1 1 auto; /* the first digit is 'flex-grow: 1', helps element to occupy all free space */
    border-bottom: solid 1px #fff;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }

  @each $color, $value in $theme-colors {
    &.border-#{$color} {
      color: $value;
      &::before,
      &::after {
        border-bottom-color: $value;
      }
    }
  }

  & > * {
    flex: 0 1 auto; /* the first digit is flex-grow: 0 */
    padding: 0 5px 0 5px;
  }
}
