$feed-search-form-box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.13);
$feed-search-border-size: 1px;

.feed-search-form {
  position: relative;
  width: 100%;

  &.feed-search-form--open {
    .search-group {
      box-shadow: $feed-search-form-box-shadow;
    }

    .form-control {
      padding-left: map-get($map: $spacers, $key: 4);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .icon-search {
      display: none;
    }
  }

  // Prevent zoom on mobile iOS
  input {
    font-size: 16px;
  }
}

.feed-search-suggestion-container {
  position: absolute;
  z-index: $zindex-dropdown;
  margin-top: -$feed-search-border-size;
  width: 100%;
  background-color: $dropdown-bg;
  border: $feed-search-border-size solid $border-secondary;
  border-top: 0;
  border-bottom-right-radius: $dropdown-border-radius;
  border-bottom-left-radius: $dropdown-border-radius;
  box-shadow: $feed-search-form-box-shadow;
  padding-bottom: $dropdown-padding-y;

  @include media-breakpoint-down(lg) {
    top: 100%;
  }

  .list-group {
    border-radius: 0;
  }

  .list-group-item-heading {
    font-size: 0.813rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: map-get($map: $spacers, $key: 3);
    letter-spacing: 0.0625rem;
  }

  .list-group-item {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    border: 0;

    &:first-child,
    &:last-child {
      border-radius: 0;
    }

    &.list-group-item--bordered {
      border-top: $feed-search-border-size solid $border-secondary;
    }
  }
}

.search-group {
  display: flex;
  align-items: center;
  width: 29.5%;

  @include media-breakpoint-down(md) {
    width: 25.5%;
  }

  @include media-breakpoint-down(sm) {
    width: 33%;
  }

  &.form-group {
    margin: 0;

    input {
      padding: px-to-rem(10px) px-to-rem(10px) px-to-rem(10px) px-to-rem(40px);
    }

    .icon {
      margin-left: px-to-rem(10px);
      font-size: px-to-rem(20px);
      position: absolute;
    }
  }
}
