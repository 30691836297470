/********************************* CURRENTLY BEING USED ***********************************/
.selected-channel {
  background-color: $denim-blue;
  color: #ffffff;
}

.nav-structure {
  border-radius: 2px;
}

.navigation-topic {
  font-size: 14px;
  border: 0 !important;
  font-family: $font-family-base;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #6b778b !important;
  text-align: left;
  outline: 0 !important;
}

.navigation-item {
  padding: map-get($map: $spacers, $key: 2);
  font-size: 14px;
}

.navigation-more-button {
  cursor: pointer;
  color: #3f6296;
  display: flex;
  align-items: center;
}

.navigation-divider {
  margin: map-get($map: $spacers, $key: 1) map-get($map: $spacers, $key: 2);
  background-color: $lighter-gray;
  height: 1px;
}

.nav-manage-channels-button {
  font-family: $font-family-base;
  cursor: pointer;
  color: #3d76c8;
  display: flex;
  align-items: center;
}

.topic-list-expand {
  transform: rotate(0deg);
  transition: transform 0.2s;
}

.topic-list-open {
  // Translate down 2px to line up chevron with text
  transform: rotate(180deg) translateY(-2px);
}

/***************************** BELOW NEED EVALUATION OF USE *******************************/

.feed-sidebar-left-container {
  .recommendation {
    width: 100%;
    height: 40px;
    background-image: $color-default-gradient;
    line-height: 40px;
    color: $color-white;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 14px;
    }
  }

  .recommendation-link {
    background-color: transparent !important;
    border: 0 !important;
    margin: 0 6px 10px 0;
    font-family: $font-family-base;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: #3f6296 !important;
    padding: 0.25rem !important;
    text-align: left;
    outline: 0 !important;
    cursor: pointer;
  }
}

.dropdown-nav-button {
  display: flex;
  justify-content: space-between;
  opacity: 0.7;
  padding: 0px !important;
}

.dropdown-nav-button-active {
  opacity: 1;
}

.navigation-button-icon {
  padding-right: 8px;
  margin-top: -2px;
}

.navigation-button-filter {
  padding: 12px 0 15px 0;
  cursor: pointer;
  color: #3d76c8;
  display: block;
  text-align: center;
  font-size: 13px;
}

.dropdown-navigation-header {
  padding: 15px 0 10px 0;
  font-family: $font-family-base;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.92px;
  text-align: center;
  color: #1a252b;
}
