.blink .f1-toast {
  opacity: 1;
  animation: blinker 300ms linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.f1-toast {
  position: relative;
  line-height: 1.1rem;
  background-clip: border-box;
  backdrop-filter: none;
  border-radius: 0;
  border: none !important;
  color: white;

  .alert-link {
    color: white;
  }

  svg path {
    fill: white !important;
  }

  &.alert-danger {
    color: white;
    background-color: $darker-red !important;
  }

  &.alert-info {
    color: white;
    background-color: $medium-blue !important;
  }

  &.alert-success {
    color: $white;
    background-color: $darker-green !important;

    svg path {
      fill: $white !important;
    }

    .alert-dismissible .close {
      bottom: 0;
      color: $white;
    }
  }

  .alert-dismissible .close {
    bottom: 0;
    color: white;
  }

  .close {
    opacity: 1;
  }

  .toast-header {
    color: white;
    background-color: transparent;
    justify-content: center;
    border-bottom: none;

    .mr-auto {
      margin-right: inherit !important;
    }

    .ml-2 {
      margin-left: 0 !important;
    }
  }
}
