.form-group {
  .is-invalid {
    box-shadow: 0 0 4px 1px #ed1c30;
    border-color: white;
  }

  .radio-list.is-invalid,
  .button-list.is-invalid {
    box-shadow: none;
    border-color: inherit;

    .custom-control-label::before,
    button {
      box-shadow: 0 0 4px 1px #ed1c30;
      border-color: white;
    }

    button {
      box-shadow: 0 0 4px 1px #ed1c30;
      border-color: white !important;
    }
  }

  .is-invalid-invisible ~ .invalid-feedback,
  .is-invalid-invisible ~ .invalid-tooltip,
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip {
    display: block;
  }

  label,
  .label {
    line-height: 1.5rem;
    color: theme-color("blue-gray");
    margin-bottom: 0.3rem;
    @extend .helv-med;
  }
}

.autoresize-textarea-container {
  width: 100%;
  border-color: #ccd3d8;
  border-radius: 1px;
  border-style: solid;
  border-width: 1px;
  padding: 10px 15px 10px 15px;

  .autoresize-textarea {
    border: none;
    padding: 0px;
  }
}
.autoresize-textarea {
  width: 100%;
  resize: none;
  border-color: #ccd3d8;
  border-radius: 1px;
  line-height: 1rem;
  padding: 10px 15px 10px 15px;
  border-style: solid;
  border-width: 1px;
}

.fake-comment-input-container {
  // Reduced padding top to account for fake post button's padding
  padding: 6px 12px;
  line-height: 1.5rem;
  border-radius: 4px;

  .fake-text-input {
    color: theme-color("battleship-gray");
  }

  .fake-post-button {
    padding: 6px 12px;
    border-radius: 4px;
  }

  .fake-post-button:hover {
    background-color: theme-color("cool-blue");
    color: theme-color("white");
  }
}

.form-text {
  font-size: 0.813rem;
}
