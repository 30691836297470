.list-group-border-x {
  border-top: $list-group-border-width solid $list-group-border-color;
  border-bottom: $list-group-border-width solid $list-group-border-color;
}

$list-group-spacing: map-merge(
  $spacers,
  (
    2_5: midpoint(spacer(2), spacer(3))
  )
);

@each $key, $value in $list-group-spacing {
  .list-group-spacing-y-#{$key} {
    .list-group-item {
      margin-top: $value;
    }

    .list-group-item:first-child {
      margin-top: 0;
    }
  }

  .list-group-spacing-x-#{$key} {
    .list-group-item {
      margin-right: $value;
    }

    .list-group-item:last-child {
      margin-right: 1px;
    }
  }
}
