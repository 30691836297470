.card-container {
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;

  .f1-carousel-control {
    display: block;
    text-align: center;

    .f1-carousel-control-btn {
      margin-top: calc(100% + 6px);
    }
  }
}

.header-title {
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
}
