@each $color, $value in $theme-colors {
  .tooltip-#{$color} {
    .tooltip-inner {
      background: $value;
    }

    &.bs-tooltip-top .arrow::before {
      border-top-color: $value;
    }

    &.bs-tooltip-right .arrow::before {
      border-right-color: $value;
    }

    &.bs-tooltip-bottom .arrow::before {
      border-bottom-color: $value;
    }

    &.bs-tooltip-left .arrow::before {
      border-left-color: $value;
    }
  }
}
