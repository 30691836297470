.base-empty-feed {
  padding-top: 60px;

  .child-container {
    max-width: 400px;
  }
  .cta-btn {
    min-width: 150px;
  }
}
