@import "../../assets/scss/all/custom/colors";

.deleted-container {
  color: $textSecondary;
  padding: 0.5rem 0rem;
  margin: 1rem 0px;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.1px;
}

.comment {
  word-break: break-word;
}
