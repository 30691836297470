@import "../../../assets/scss/all/custom/variables";

.deletedPostDialog {
  width: 100%;

  h2 {
    text-align: left;
  }
}

.strap {
  background-color: $secondary;
  margin: 0px -40px;
  padding: 10px 40px;
}
