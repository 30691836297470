@import "../../../assets/scss/all/custom/colors";

.settings-navigation {
  .nav-item {
    margin-bottom: 9px;
  }
  .nav-link {
    display: block;
    padding: 10px 13px 12px;
    border-radius: 4px;
    background: #ffffff;
    color: $textSecondary;

    &.active {
      background: $denim-blue;
      color: #ffffff;
    }
  }
}

.settings-mobile-nav {
  padding: 10px 0px 10px 40px;
}
