@import "../../assets/scss/style.scss";

.groupSpashPage {
  h1 {
    max-width: none;
    font-size: text-size(28);
    margin: 10vh 2vh;
  }
  p {
    margin-left: 2vh;
    margin-right: 2vh;
  }
  ul {
    padding-left: 0;
    max-width: none;
    margin-top: 3vh;
  }

  @include media-breakpoint-up(lg) {
    h1 {
      max-width: 50vw;
      font-size: text-size(40);
      margin-top: 24vh;
    }
  }
}

.selfGroupDetailBox {
  box-shadow: 0 8px 20px 2px rgba(152, 152, 152, 0.24);
}
