@import "colors";

@mixin suggested-color {
  color: $suggested-color;
}

@mixin flagged-color {
  color: $flagged-color;
}

@mixin reported-color {
  color: $reported-color;
}

@mixin approved-color {
  color: $approved-color;
}

@mixin colored-dot {
  content: "•";
}

@mixin col-border {
  height: inherit;
  border-right-color: $page-bg-color;
  border-right-width: 3px;
  border-right-style: solid;
}

@mixin col-border-bottom {
  border-bottom-color: $page-bg-color;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

@mixin text($size) {
  font-size: text-size($size) !important;
}

@mixin dropdown-menu-pointer-root(
  $width,
  $position,
  $border-width,
  $border-color,
  $dropdown-bg-color
) {
  &::after,
  &::before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: scale-color($dropdown-bg-color, $alpha: -100%);
    border-bottom-color: $dropdown-bg-color;
    border-width: $width;
    left: $position;
    margin-left: -$width;
  }

  &::before {
    border-color: scale-color($border-color, $alpha: -100%);
    border-bottom-color: $border-color;
    border-width: $width + $border-width;
    left: $position;
    margin-left: -($width + $border-width);
  }
}

@mixin dropdown-menu-pointer($width, $position) {
  @include dropdown-menu-pointer-root(
    $width,
    $position,
    0,
    $dropdown-menu-pointer-border-color,
    $dropdown-menu-pointer-bg-color
  );
}

@mixin card-flush-variant($border-width, $border-color, $border-radius) {
  border: 0;
  border-radius: 0;

  .card-header {
    border: $border-width solid transparent;
    border-bottom: 0;
    border-radius: $border-radius $border-radius 0 0;
  }

  .card-body {
    border: $border-width solid $border-color;
    border-top: 0;
    border-radius: 0 0 $border-radius $border-radius;
    overflow: hidden;
  }
}

// The generate-utility mixin is from Bootstrap's 5 utility API.
// For more info see: https://getbootstrap.com/docs/5.0/utilities/api/

// Utility generator
// Used to generate utilities & print utilities
// prettier-ignore
@mixin generate-utility($utility, $infix) {
  $values: map-get($utility, values);

  // If the values are a list or string, convert it into a map
  @if type-of($values) == "string" or type-of(nth($values, 1)) != "list" {
    $values: zip($values, $values);
  }

  @each $key, $value in $values {
    $properties: map-get($utility, property);

    // Multiple properties are possible, for example with vertical or horizontal margins or paddings
    @if type-of($properties) == "string" {
      $properties: append((), $properties);
    }

    // Use custom class if present
    $property-class: if(
      map-has-key($utility, class),
      map-get($utility, class),
      nth($properties, 1)
    );
    $property-class: if($property-class == null, "", $property-class);

    // State params to generate pseudo-classes
    $state: if(map-has-key($utility, state), map-get($utility, state), ());

    $infix: if(
      $property-class == "" and str-slice($infix, 1, 1) == "-",
      str-slice($infix, 2),
      $infix
    );

    // Don't prefix if value key is null (eg. with shadow class)
    $property-class-modifier: if(
      $key,
      if($property-class == "" and $infix == "", "", "-") + $key,
      ""
    );

    @if $value != null {
      .#{$property-class + $infix + $property-class-modifier} {
        @each $property in $properties {
          #{$property}: $value if($enable-important-utilities, !important, null);
        }
      }

      @each $pseudo in $state {
        .#{$property-class + $infix + $property-class-modifier}-#{$pseudo}:#{$pseudo} {
          @each $property in $properties {
            #{$property}: $value if($enable-important-utilities, !important, null);
          }
        }
      }
    }
  }
}
