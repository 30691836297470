@import "../../assets/scss/all/custom/colors";

.network-nav {
  .nav-link {
    text-align: center;
    border-bottom: 3px solid $light-gray;
    color: $textSecondary;

    &.active {
      border-color: $denim-blue;
      color: $denim-blue;
    }
  }
}
