@import "../../../assets/scss/style";

.notif-markdown {
  p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
  }
}

.bg-unread {
  background-color: rgba(
    $color: theme-color("cool-blue"),
    $alpha: 0.16
  ) !important;
}
