@import "../../assets/scss/style.scss";

.spashPage {
  h1 {
    max-width: none;
    font-size: text-size(28);
    margin: 10vh 10vw 5vh;
  }

  @include media-breakpoint-up(lg) {
    h1 {
      max-width: 50vw;
      font-size: text-size(40);
      margin-top: 24vh;
    }
  }
}
