.profile-about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: $profile-margin-y;
  font-size: 1rem;

  .profile-img-container {
    position: relative;
    margin-bottom: $profile-margin-y;
  }

  .profile-img {
    object-fit: cover;
    box-shadow: 0 px-to-rem(2px) px-to-rem(20px) 0 rgba(0, 0, 0, 0.2);
  }

  .btn-avatar-upload {
    position: absolute;
    bottom: px-to-rem(10px);
    right: px-to-rem(16px);
  }
}

.profile-img-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: px-to-rem(175px);
  filter: blur(px-to-rem(14px));
  overflow: hidden;
}

.profile-section-header {
  top: px-to-rem(67px);
  z-index: $zindex-sticky - 1;
}
