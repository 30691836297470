$p-edit-modal-input-border-radius: px-to-rem(2px);
$p-edit-modal-spacing-x: spacer(4);
$p-edit-modal-spacing-y: spacer(4);

.modal-profile {
  .close {
    position: absolute;
    top: spacer(2);
    right: spacer(2);
  }

  .modal-header {
    padding: spacer(5) $p-edit-modal-spacing-x spacer(2) $p-edit-modal-spacing-x;
  }

  .modal-body {
    padding: $p-edit-modal-spacing-y $p-edit-modal-spacing-x;
  }

  .modal-footer {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 0;
    padding: $p-edit-modal-spacing-y $p-edit-modal-spacing-x;

    .btn {
      min-width: 6rem;
      padding: spacer(2) spacer(4);
    }
  }

  input,
  textarea {
    border-radius: $p-edit-modal-input-border-radius;
    border: solid px-to-rem(1px) theme-color("light-gray");
  }

  .form-group {
    margin-bottom: $p-edit-modal-spacing-y;
  }
}

.modal-profile-edit {
  .modal-header {
    background-color: theme-color("lighter-gray");
    color: theme-color("denim-blue");
    padding-bottom: spacer(1);
  }

  .modal-title {
    font-size: text-size(13);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: px-to-rem(1.08px);
  }

  .modal-footer {
    padding-top: 0;
  }
}

.modal-profile-edit-info {
  .modal-header {
    background-color: theme-color("white");
    color: theme-color("default-black");
    padding-left: 0;
    padding-right: 0;
    margin-left: $p-edit-modal-spacing-x;
    margin-right: $p-edit-modal-spacing-x;
    border-bottom: px-to-rem(3px) solid theme-color("denim-blue");
  }

  .modal-title {
    font-size: text-size(16);
    font-weight: bold;
  }

  .modal-body {
    overflow-y: auto;
    max-height: 34rem;
  }

  .modal-footer {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      pointer-events: none;
      box-shadow: px-to-rem(10px) px-to-rem(2px) px-to-rem(24px) 0
        rgba(0, 0, 0, 0.15);
    }
  }
}

.input-group-plain {
  .input-group-text {
    background: none;
    border: 0;
  }
}
