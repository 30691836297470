@import "../../assets/scss/all/custom/colors";

.settings-header {
  &.mobile-mode {
    & > .col-lg-3 {
      padding: 0px !important;
      background: $denim-blue;
      color: #ffffff;
    }
  }
}
