//  One off styles to accommodate design needs
.btn {
  &.btn-cme-activity.disabled,
  &.btn-cme-activity:disabled {
    background-color: #464d5a;
    border-color: #464d5a;
    opacity: 1;
  }

  &.btn-cme-question-back {
    border-color: #768195;
  }
}
