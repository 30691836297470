// This is only used here for now, so I'm not going
// to make it all reusable yet. -- Corey

.blue-background {
  background: #223354;
}

.legacy-onboarding-page {
  ul {
    list-style-type: none;
    padding: 0;
  }
}
